// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-garden-js": () => import("./../src/pages/garden.js" /* webpackChunkName: "component---src-pages-garden-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-notes-js": () => import("./../src/pages/notes.js" /* webpackChunkName: "component---src-pages-notes-js" */),
  "component---src-pages-now-js": () => import("./../src/pages/now.js" /* webpackChunkName: "component---src-pages-now-js" */),
  "component---src-pages-posts-js": () => import("./../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-quotes-js": () => import("./../src/pages/quotes.js" /* webpackChunkName: "component---src-pages-quotes-js" */),
  "component---src-pages-tech-notes-js": () => import("./../src/pages/tech-notes.js" /* webpackChunkName: "component---src-pages-tech-notes-js" */),
  "component---src-templates-concept-template-js": () => import("./../src/templates/concept-template.js" /* webpackChunkName: "component---src-templates-concept-template-js" */),
  "component---src-templates-garden-template-js": () => import("./../src/templates/garden-template.js" /* webpackChunkName: "component---src-templates-garden-template-js" */),
  "component---src-templates-note-template-js": () => import("./../src/templates/note-template.js" /* webpackChunkName: "component---src-templates-note-template-js" */),
  "component---src-templates-post-template-js": () => import("./../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

