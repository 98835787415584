import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { Code } from "./src/components/Code"
import { preToCodeBlock } from "mdx-utils"

const MyParagraph = ({ children, ...props }) => {
  return <p>{children}</p>
}

const MyLi = ({ children, ...props }) => {
  return <li>{children}</li>
}

const MyH2 = ({ children, ...props }) => {
  return <h2>{children}</h2>
}

const MyH3 = ({ children, ...props }) => {
  return <h3>{children}</h3>
}

// components is its own object outside of render so that the references to
// components are stable
const components = {
  pre: preProps => {
    const props = preToCodeBlock(preProps)
    // if there's a codeString and some props, we passed the test
    if (props) {
      return <Code {...props} />
    } else {
      // it's possible to have a pre without a code in it
      return <pre {...preProps} />
    }
  },
  p: MyParagraph,
  h2: MyH2,
  h3: MyH3,
  li: MyLi,
}
export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
)
